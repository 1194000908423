import { Component, OnInit } from '@angular/core';
import { DashboardStats } from './../../models/dashboardStats';
import { DashboardService } from './../../services/dashboard.service';

@Component({
  selector: 'app-stats-by-province-widget',
  templateUrl: './stats-by-province-widget.component.html'
})
export class StatsByProvinceWidgetComponent implements OnInit {

  stats: DashboardStats[] = [];

  constructor(private dashboardService: DashboardService) {
  }

  ngOnInit() {
    this.dashboardService.getStatsGroupByProvince().subscribe(result => {
      const totalStats: DashboardStats = <DashboardStats>{};
      totalStats.description = 'TOTALES';
      totalStats.mayors = 0;
      totalStats.vocal = 0;
      totalStats.aldermen = 0;
      totalStats.deputies = 0;
      totalStats.directors = 0;
      totalStats.senators = 0;

      result.forEach(value => {
        totalStats.senators += value.senators;
        totalStats.mayors += value.mayors;
        totalStats.aldermen += value.aldermen;
        totalStats.deputies += value.deputies;
        totalStats.directors += value.directors;
        totalStats.vocal += value.vocal;
      });
      result.push(totalStats);
      this.stats = result;
    }, error => {
      console.log(error);
    });
  }
}
