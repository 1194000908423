import { Component, OnInit } from '@angular/core';
import { DashboardStats } from './../../models/dashboardStats';
import { PoliticalLevel } from '../../models/political-level';
import { DashboardService } from './../../services/dashboard.service';
import { PoliticalLevelService } from './../../services/political-level.service';
import { PoliticalLevelHomologation } from '../../models/political-level-homologation';
import { ElectoralCommissionStats } from '../../models/electoral-commission-stats';

@Component({
  selector: 'app-stats-by-commissions-table-widget',
  templateUrl: './stats-by-commissions-table-widget.component.html'
})
export class StatsByCommissionsTableWidgetComponent implements OnInit {

  stats: DashboardStats[] = [];
  cols: any[];
  sortName = null;
  sortValue = null;
  politicalLevels: PoliticalLevel[] = [];
  selectedPoliticalLevel: PoliticalLevel;
  isLoading = false;
  electoralCommissionStats: ElectoralCommissionStats = <ElectoralCommissionStats>{};
  absoluteQuantity = 0;
  private politicalHmologation = PoliticalLevelHomologation;

  constructor(private dashboardService: DashboardService, private politicalLevelService: PoliticalLevelService) {
  }

  ngOnInit() {
    this.politicalLevelService.getPoliticalLevelsGroupByProvinces().subscribe(result => {
      this.politicalLevels = result;
      this.selectedPoliticalLevel = this.politicalLevels[0];
      this.loadStatsByProvinceId(this.selectedPoliticalLevel.proviceId);
    }, error => {
      console.log(error);
    });
  }

  loadStatsByProvinceId(provinceId) {
    this.activeLoading();
    this.dashboardService.getStatsByProvinceId(provinceId).subscribe(result => {
      this.stats = result;
      this.deactivateLoading();
    }, error => {
      console.log(error);
      this.deactivateLoading();
    });

    this.dashboardService.getElectoralCommissionStats(provinceId).subscribe(result => {
      this.electoralCommissionStats = result[0];
    });
  }

  loadStatsByProvinceHomologation(provinceId: string) {
    this.loadStatsByProvinceId(this.politicalHmologation[provinceId]);
  }

  activeLoading() {
    this.isLoading = true;
  }

  deactivateLoading() {
    this.isLoading = false;
  }

}
