import { Component, OnInit } from '@angular/core';
import { DashboardService } from '../../services/dashboard.service';

export interface AldermenTransmission {
  province: string;
  municipality: string;
  circumscription: string;
  alderman: number;
}

@Component({
  selector: 'app-transmission-table-alderman',
  templateUrl: './transmission-table-alderman.component.html',
  styleUrls: ['./transmission-table-alderman.component.css']
})
export class TransmissionTableAldermanComponent implements OnInit {

  cols: any[];
  stats: AldermenTransmission[];
  statsCopy: AldermenTransmission[];

  constructor(private dashboardService: DashboardService) {
  }

  async ngOnInit() {
    this.cols = [
      {field: 'province', header: 'Provincia'},
      {field: 'circumscription', header: 'Circumscripción'},
      {field: 'municipality', header: 'Municipio'},
      {field: 'alderman', header: 'Regidores'}];

    this.stats = await this.dashboardService.getAldermanTransmission().toPromise();
    const transmission: AldermenTransmission = <AldermenTransmission>{};
    transmission.province = 'Totales';
    transmission.municipality = '';
    transmission.circumscription = '';
    transmission.alderman = 0;
    this.stats.forEach(value => {
      transmission.alderman += value.alderman;
    });

    this.stats.push(transmission);

    this.statsCopy = [...this.stats];

  }

  onFilterChange(target) {

    if (target.value === 'OutOfTransmission') {
      this.statsCopy = [...this.stats];
      this.stats = [...this.stats.filter(value => value.alderman === 0)];
    } else {
      if (this.statsCopy.length > 0) {
        this.stats = [...this.statsCopy];
      }
    }

  }

  download() {
    const replacer = (key, value) => value === null ? '' : value; // specify how you want to handle null values here
    const header = Object.keys(this.stats[0]);
    const csv = this.stats.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','));
    csv.unshift(this.cols.map(value => value.header).join(','));
    const csvArray = csv.join('\r\n');

    const a = document.createElement('a');
    const blob = new Blob([csvArray], {type: 'text/csv'}),
      url = window.URL.createObjectURL(blob);

    a.href = url;
    a.download = 'Resultados de regidores.csv';
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
  }
}
