import { PoliticalLevel } from '../models/political-level';
import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class PoliticalLevelService {

  constructor(@Inject('BASE_URL') private baseUrl: string, private http: HttpClient) { }

  getPoliticalLevelsGroupByProvinces() {
    return this.http.get<PoliticalLevel[]>(`${this.baseUrl}api/political-levels/provinces`);
  }

  getMunicipalitiesByProvinceIdAndCircumscriptionId(provinceId: number, circumscription: string) {
    return this.http.get<PoliticalLevel[]>(`
      ${this.baseUrl}api/political-levels/provinces/${provinceId}/circumscriptions/${circumscription}/municipalities`
    );
  }

  getCircumscriptionsByProvinceId(provinceId: number) {
    return this.http.get<PoliticalLevel[]>(`${this.baseUrl}api/political-levels/provinces/${provinceId}/circumscriptions`);
  }

  getMunicipalitiesByProvinceId(provinceId: number) {
    return this.http.get<PoliticalLevel[]>(`
      ${this.baseUrl}api/political-levels/provinces/${provinceId}/municipalities`
    );
  }

  getMunicipalDistricsByMunicipalityId(munipalityId: number) {
    return this.http.get<PoliticalLevel[]>(`
      ${this.baseUrl}api/political-levels/municipalities/${munipalityId}/municipalDistrics`
    );
  }
}
