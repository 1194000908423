import { Component, OnInit } from '@angular/core';
import { DashboardService } from '../../services/dashboard.service';

export interface DirectorVocalTransmission {
  vocal: number;
  director: number;
  province: string;
  municipality: string;
  municipalDistricName: string;
}

@Component({
  selector: 'app-transmission-table-directors-vocals',
  templateUrl: './transmission-table-directors-vocals.component.html',
  styleUrls: ['./transmission-table-directors-vocals.component.css']
})
export class TransmissionTableDirectorsVocalsComponent implements OnInit {
  cols: any[];
  stats: DirectorVocalTransmission[];
  statsCopy: DirectorVocalTransmission[];

  constructor(private dashboardService: DashboardService) {
  }

  async ngOnInit() {
    this.cols = [
      {field: 'province', header: 'Provincia'},
      {field: 'municipality', header: 'Municipio'},
      {field: 'municipalDistricName', header: 'Distrito municipal'},
      {field: 'director', header: 'Directores'},
      {field: 'vocal', header: 'Vocales'}
    ];

    this.stats = await this.dashboardService.getDirectorsVocalsTransmission().toPromise();
    const transmission: DirectorVocalTransmission = <DirectorVocalTransmission>{};
    transmission.province = 'Totales';
    transmission.municipality = '';
    transmission.municipalDistricName = '';
    transmission.vocal = 0;
    transmission.director = 0;
    this.stats.forEach(value => {
      transmission.director += value.director;
      transmission.vocal += value.vocal;
    });

    this.stats.push(transmission);

    this.statsCopy = [...this.stats];

  }

  onFilterChange(target) {

    if (target.value === 'OutOfTransmission') {
      this.statsCopy = [...this.stats];
      this.stats = [...this.stats.filter(value => value.director === 0 && value.vocal === 0)];
    } else {
      if (this.statsCopy.length > 0) {
        this.stats = [...this.statsCopy];
      }
    }

  }

  download() {
    const replacer = (key, value) => value === null ? '' : value; // specify how you want to handle null values here
    const header = this.cols.map(value => value.field);
    const csv = this.stats.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','));
    csv.unshift(this.cols.map(value => value.header).join(','));
    const csvArray = csv.join('\r\n');

    const a = document.createElement('a');
    const blob = new Blob([csvArray], {type: 'text/csv'}),
      url = window.URL.createObjectURL(blob);

    a.href = url;
    a.download = 'Resultados de Directores y Vocales.csv';
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
  }
}
