import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-candidates-progressbar',
  templateUrl: './candidates-progressbar.component.html',
  styleUrls: ['./candidates-progressbar.component.css']
})
export class CandidatesProgressbarComponent implements OnInit {
  @Input() current: number;
  @Input() max: number;
  @Input() title: string;
  @Input() quantity: string;
  @Input() showUnits = false;
  @Input() color = '#1883C3';

  constructor() {
  }

  ngOnInit() {
  }

}
