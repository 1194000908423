import { Component, OnInit } from '@angular/core';
import { CurrentUserService } from '../services/current-user.service';

@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.css']
})
export class NavMenuComponent implements OnInit {
  isExpanded = true;
  username = '';
  isMenuVisible = false;

  constructor(private currentUserService: CurrentUserService) {
  }

  collapse() {
    this.isExpanded = false;
  }

  toggle() {
    const body = document.getElementsByTagName('BODY')[0];
    const sidebar = document.getElementsByClassName('sidebar')[0];
    if (this.isExpanded) {
      body.className = 'sidebar-toggled';
      sidebar.className = 'sidebar  navbar-nav sidebar-style toggled';
    } else {
      body.className = '';
      sidebar.className = 'sidebar navbar-nav sidebar-style';
    }


    this.isExpanded = !this.isExpanded;
  }

  ngOnInit(): void {
    this.username = this.currentUserService.currentUser.username;
  }

  showMenu() {
    console.log('entro');
    this.isMenuVisible = !this.isMenuVisible;
  }
}
