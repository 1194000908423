import { Component, Input, OnInit } from '@angular/core';
import { DashboardStats } from './../../models/dashboardStats';
import { PoliticalLevel } from '../../models/political-level';

@Component({
  selector: 'app-table-stats',
  templateUrl: './table-stats.component.html'
})
export class TableStatsComponent implements OnInit {

  @Input() description: string;
  @Input() stats: DashboardStats[] = [];
  @Input() cardHeader: string;
  @Input() filter = 'All';
  @Input() hasFilter = false;
  cols: any[];
  sortName = null;
  sortValue = null;
  politicalLevels: PoliticalLevel[] = [];
  selectedPoliticalLevel: PoliticalLevel;
  statsCopy: DashboardStats[] = [];

  ngOnInit() {
    this.cols = [
      {field: 'description', header: this.description},
      {field: 'circumscription', header: 'Circ.'},
      {field: 'quantity', header: 'Senadores'},
      {field: 'deputies', header: 'Diputados'},
      {field: 'mayors', header: 'Alcaldes'},
      {field: 'aldermen', header: 'Regidores'},
      {field: 'directors', header: 'Directores'},
      {field: 'vocal', header: 'Vocales'},
      {field: 'total', header: 'Total'}
    ];
    this.statsCopy = this.stats;
  }

  onSortData(sort: { key: string, value: string }) {
    this.sortName = sort.key;
    this.sortValue = sort.value;
    this.sortData();
  }

  onFilterChange(target) {

    if (target.value === 'OutOfTransmission') {
      this.statsCopy = [...this.stats];
      this.stats = [...this.stats.filter(value => value.senators === 0 && value.directors === 0
        && value.deputies === 0 && value.aldermen === 0 && value.vocal === 0 && value.mayors === 0)];
    } else {
      if (this.statsCopy.length > 0) {
        this.stats = [...this.statsCopy];
      }
    }

  }

  private sortData(): void {
    if (this.sortName && this.sortValue) {
      const data = this.stats.sort((a, b) => (this.sortValue === 'ascend') ?
        (a[this.sortName] > b[this.sortName]
          ? 1 : -1) : (b[this.sortName] > a[this.sortName] ? 1 : -1));
      this.stats = [...data];
    } else {
      this.stats = [...this.stats];
    }
  }
}
