import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { CurrentUserService } from './current-user.service';
import { NzNotificationService } from 'ng-zorro-antd';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private currentService: CurrentUserService, private router: Router, private notification: NzNotificationService) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    if (!this.currentService.isLogged) {
      this.router.navigate(['login']);
      return false;
    }

    if (this.currentService.currentUser.role === 2 && (next.routeConfig.path !== 'consulta')) {
      this.notification.error('Permisos', 'No tiene suficiente permisos para acceder a esta ruta');
      this.router.navigate(['consulta']);
      return false;
    }

    return true;
  }
}
