import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SidebarService {

  private isFullScreen = new BehaviorSubject<boolean>(false);

  constructor() {
  }

  watchIsFullScreen() {
    return this.isFullScreen.asObservable();
  }

  changeFullScreenMode(isFullScreen: boolean) {
    this.isFullScreen.next(isFullScreen);
  }


}
