import { Component, OnInit } from '@angular/core';
import { DashboardService } from '../../services/dashboard.service';

export interface SenatorsTransmission {
  quantity: number;
  province: string;
}

@Component({
  selector: 'app-transmission-table-senators',
  templateUrl: './transmission-table-senators.component.html',
  styleUrls: ['./transmission-table-senators.component.css']
})
export class TransmissionTableSenatorsComponent implements OnInit {

  cols: any[];
  stats: SenatorsTransmission[];
  statsCopy: SenatorsTransmission[];

  constructor(private dashboardService: DashboardService) {
  }

  async ngOnInit() {
    this.cols = [
      {field: 'province', header: 'Provincia'},
      {field: 'quantity', header: 'Senadores'}];

    this.stats = await this.dashboardService.getSenatorsTransmission().toPromise();
    const transmission: SenatorsTransmission = <SenatorsTransmission>{};
    transmission.province = 'Totales';
    transmission.quantity = 0;
    this.stats.forEach(value => {
      transmission.quantity += value.quantity;
    });

    this.stats.push(transmission);

    this.statsCopy = [...this.stats];
  }

  onFilterChange(target) {

    if (target.value === 'OutOfTransmission') {
      this.statsCopy = [...this.stats];
      this.stats = [...this.stats.filter(value => value.quantity === 0)];
    } else {
      if (this.statsCopy.length > 0) {
        this.stats = [...this.statsCopy];
      }
    }

  }

  download() {
    const replacer = (key, value) => value === null ? '' : value; // specify how you want to handle null values here
    const customHeader = ['Provincia', 'Senadores'];
    const header = Object.keys(this.stats[0]);
    const csv = this.stats.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','));
    csv.unshift(customHeader.join(','));
    const csvArray = csv.join('\r\n');

    const a = document.createElement('a');
    const blob = new Blob([csvArray], {type: 'text/csv'}),
      url = window.URL.createObjectURL(blob);

    a.href = url;
    a.download = 'Resultados de senadores.csv';
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
  }

}
