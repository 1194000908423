import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DocumentTypes } from '../models/document-types';

@Injectable({
  providedIn: 'root'
})
export class DocumentsService {

  constructor(private http: HttpClient, @Inject('BASE_URL') private baseUrl: string) {
  }

  getDocumentTypes() {
    return this.http.get<DocumentTypes[]>(`${this.baseUrl}api/documents/document-types`);
  }

  getDocumentImage(id: number) {
    return this.http.get(`${this.baseUrl}api/documents/${id}/image`, {responseType: 'arraybuffer'});
  }
}
