import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Candidate } from '../models/candidate';
import { Document } from '../models/document';
import { ConsultCandidateViewModel } from '../models/consult-candidate-view-model';
import { CandidateContacts } from '../models/candidate-contacts';

@Injectable({
  providedIn: 'root'
})
export class CandidatesService {

  constructor(@Inject('BASE_URL') private baseUrl: string, private http: HttpClient) {
  }

  getAllCandidates() {
    return this.http.get<ConsultCandidateViewModel[]>(`${this.baseUrl}api/candidates`);
  }

  getCandidadeByLevel(id: number) {
    return this.http.get<Candidate[]>(`${this.baseUrl}api/candidates`);
  }

  getCandidatesById(id: number) {
    return this.http.get<Candidate>(`${this.baseUrl}api/candidates/${id}`);
  }

  editCandidate(rawValue: any, documents: Document[], candidateId: number) {
    const formData: FormData = new FormData();

    formData.append('nickname', rawValue['nickname']);
    documents.forEach(file => {
      formData.append('file', file.file);
      formData.append('documentTypeId', file.documentTypeId.toString());
    });
    formData.append('levelId', rawValue['level']);
    formData.append('provinceId', rawValue['province'] === null ? 0 : rawValue['province']);
    formData.append('municipalityId', rawValue['municipality'] === null ? 0 : rawValue['municipality']);
    formData.append('circumscription', rawValue['circumscription'] === null ? 0 : rawValue['circumscription']);
    formData.append('municipalDistricId', rawValue['municipalDistric'] === null ? 0 : rawValue['municipalDistric']);
    return this.http.put(`${this.baseUrl}api/candidates/${candidateId}`, formData);
  }

  getCandidateByIdentification(identification1: string) {
    return this.http.get<Candidate>(`${this.baseUrl}api/candidates/identification/${identification1}`);
  }

  deleteCandidate(id: number) {
    return this.http.delete(`${this.baseUrl}api/candidates/${id}`);
  }

  findPerson(identification: string) {
    return this.http.get<Candidate>(`${this.baseUrl}api/candidates/persons/${identification}`);
  }

  createCandidate(rawValue: any, documents: Document[]) {
    const formData: FormData = new FormData();

    formData.append('nickname', rawValue['nickname']);
    formData.append('provinceId', rawValue['provinceId'] || 0);
    formData.append('circumscription', rawValue['circumscription']);
    formData.append('municipalityId', rawValue['municipalityId'] || 0);
    formData.append('districtId', rawValue['districtId'] || 0);
    formData.append('identification', rawValue['identification']);
    formData.append('level', rawValue['level'] || 0);

    documents.forEach(file => {
      formData.append('file', file.file);
      formData.append('documentTypeId', file.documentTypeId.toString());
    });
    return this.http.post(`${this.baseUrl}api/candidates`, formData);
  }

  resignedCandidate(id: number) {
    return this.http.patch(`${this.baseUrl}api/candidates/${id}/resigned`, {});
  }

  excludeCandidate(id: number) {
    return this.http.patch(`${this.baseUrl}api/candidates/${id}/exclude-from-process`, {});
  }

  getNextCandidate() {
    return this.http.get<CandidateContacts>(`${this.baseUrl}api/candidates/contacts`);
  }

  saveContacts(form: any) {
    return this.http.post(`${this.baseUrl}api/candidates/contacts`, form);
  }

  markAsWrong(form: number) {
    return this.http.patch(`${this.baseUrl}api/candidates/contacts/${form}/wrong`, {});
  }
}

