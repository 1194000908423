import { Component, OnInit } from '@angular/core';
import { DashboardService } from '../../services/dashboard.service';

export interface DeputyTransmission {
  province: string;
  circumscription: string;
  deputies: number;
}

@Component({
  selector: 'app-transmission-table-deputies',
  templateUrl: './transmission-table-deputies.component.html',
  styleUrls: ['./transmission-table-deputies.component.css']
})
export class TransmissionTableDeputiesComponent implements OnInit {

  cols: any[];
  stats: DeputyTransmission[];
  statsCopy: DeputyTransmission[];

  constructor(private dashboardService: DashboardService) {
  }

  async ngOnInit() {
    this.cols = [
      {field: 'province', header: 'Provincia'},
      {field: 'circumscription', header: 'Circuscripción'},
      {field: 'deputies', header: 'Diputados'}];


    this.stats = await this.dashboardService.getDeputiesTransmission().toPromise();
    const transmission: DeputyTransmission = <DeputyTransmission>{};
    transmission.province = 'Totales';
    transmission.circumscription = '';
    transmission.deputies = 0;
    this.stats.forEach(value => {
      transmission.deputies += value.deputies;
    });

    this.stats.push(transmission);

    this.statsCopy = [...this.stats];
  }

  onFilterChange(target) {

    if (target.value === 'OutOfTransmission') {
      this.statsCopy = [...this.stats];
      this.stats = [...this.stats.filter(value => value.deputies === 0)];
    } else {
      if (this.statsCopy.length > 0) {
        this.stats = [...this.statsCopy];
      }
    }

  }

  download() {
    const replacer = (key, value) => value === null ? '' : value; // specify how you want to handle null values here
    const header = Object.keys(this.stats[0]);
    const csv = this.stats.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','));
    csv.unshift(this.cols.map(value => value.header).join(','));
    const csvArray = csv.join('\r\n');

    const a = document.createElement('a');
    const blob = new Blob([csvArray], {type: 'text/csv'}),
      url = window.URL.createObjectURL(blob);

    a.href = url;
    a.download = 'Resultados de diputados.csv';
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
  }

}
