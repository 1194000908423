import { Component, Input, OnInit } from '@angular/core';
import { ElectoralCommissionStats } from '../../models/electoral-commission-stats';

@Component({
  selector: 'app-electoral-commission-stats',
  templateUrl: './electoral-commission-stats.component.html',
  styleUrls: ['./electoral-commission-stats.component.css']
})
export class ElectoralCommissionStatsComponent implements OnInit {

  @Input() stats: ElectoralCommissionStats = <ElectoralCommissionStats>{};

  constructor() {
  }

  ngOnInit() {
  }

}
