import { Component, OnInit } from '@angular/core';
import { CurrentUserService } from '../services/current-user.service';
import { SidebarService } from '../services/sidebar.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {
  isViewer = false;
  isFullScreen = false;

  constructor(private currentUserService: CurrentUserService, private sidebarService: SidebarService) {
  }

  ngOnInit(): void {
    this.isViewer = this.currentUserService.currentUser.role > 1;
    this.sidebarService.watchIsFullScreen().subscribe(value => {
      this.isFullScreen = value;
    });
  }
}
