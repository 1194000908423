import { Component, OnInit } from '@angular/core';
import { DashboardService } from '../services/dashboard.service';
import { StatLinesPercentage } from '../models/stat-lines-percentage';
import { CountryParticipation } from '../models/country-participation';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html'
})
export class DashboardComponent implements OnInit {

  statsLine: StatLinesPercentage[] = [];
  provinceParticipation: CountryParticipation[] = [];
  isLoading = true;
  absoluteQuantity = 0;

  constructor(private dashboardService: DashboardService) {
  }

  async ngOnInit() {
    this.isLoading = true;
    this.statsLine = await this.dashboardService.getStatsLinePercentage().toPromise();

    this.provinceParticipation = await this.dashboardService.getCountryParticipation().toPromise();
    this.absoluteQuantity = await this.dashboardService.getCandidatesAbsoluteQuantity().toPromise();

    this.isLoading = false;
  }
}

