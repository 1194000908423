import { Injectable } from '@angular/core';
import { UserProfile } from '../models/user-profile';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable({
  providedIn: 'root'
})
export class CurrentUserService {
  TOKEN_KEY = 'accessToken';

  constructor(private jwtHelperService: JwtHelperService) {
  }

  public get currentUser(): UserProfile {
    return JSON.parse(localStorage.getItem(this.TOKEN_KEY)) as UserProfile;
  }

  public get isLogged(): boolean {
    const userProfile = this.currentUser;
    return !!userProfile && !this.jwtHelperService.isTokenExpired(this.currentUser === null ? '' : this.currentUser.token);
  }

  logout() {
    localStorage.removeItem(this.TOKEN_KEY);
  }

  startSession(value) {
    localStorage.setItem(this.TOKEN_KEY, JSON.stringify(value));
  }

  getToken(): string {
    return this.currentUser.token;
  }
}
