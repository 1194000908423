import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { JwtModule } from '@auth0/angular-jwt';
import { FusionChartsModule } from 'angular-fusioncharts';
import * as FusionCharts from 'fusioncharts';
import * as FusionMaps from 'fusioncharts/fusioncharts.maps';
import * as FusionWorld from 'fusioncharts/maps/fusioncharts.world';
import * as FusionTheme from 'fusioncharts/themes/fusioncharts.theme.fusion';
import * as DominicanRepublic from 'fusionmaps/maps/fusioncharts.dominicanrepublic';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { en_US, NgZorroAntdModule, NZ_I18N } from 'ng-zorro-antd';
import { IConfig, NgxMaskModule } from 'ngx-mask';
import { AppComponent } from './app.component';
import { CandidatesProgressbarComponent } from './dashboard/candidates-progressbar/candidates-progressbar.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { DominicanRepublicMapComponent } from './dashboard/dominican-republic-map/dominican-republic-map.component';
import { ElectoralCommissionStatsComponent } from './dashboard/electoral-commission-stats/electoral-commission-stats.component';
import { StatsByCommissionsTableWidgetComponent } from './dashboard/stats-by-commissions-table-widget/stats-by-commissions-table-widget.component';
import { StatsByProvinceWidgetComponent } from './dashboard/stats-by-province-widget/stats-by-province-widget.component';
import { TableStatsComponent } from './dashboard/table-stats/table-stats.component';
import { LayoutComponent } from './layout/layout.component';
import { LoginComponent } from './login/login.component';
import { NavMenuComponent } from './nav-menu/nav-menu.component';
import { AuthGuard } from './services/auth.guard';
import { TokenInterceptor } from './services/token-interceptor';
import { SidebarComponent } from './sidebar/sidebar.component';
import { TransmissionTableSenatorsComponent } from './dashboard/transmission-table-senators/transmission-table-senators.component';
import { TransmissionTableDeputiesComponent } from './dashboard/transmission-table-deputies/transmission-table-deputies.component';
import { TransmissionTableMayorsComponent } from './dashboard/transmission-table-mayors/transmission-table-mayors.component';
import { TransmissionTableAldermanComponent } from './dashboard/transmission-table-alderman/transmission-table-alderman.component';
import { TransmissionTableDirectorsVocalsComponent } from './dashboard/transmission-table-directors-vocals/transmission-table-directors-vocals.component';
import { GlobalConsultCandidatesComponent } from './global-consult-candidates/global-consult-candidates.component';
import { RecaptchaModule } from 'ng-recaptcha';
import { RecaptchaFormsModule } from 'ng-recaptcha/forms';


export const options: Partial<IConfig> | (() => Partial<IConfig>) = {};

export function jwtTokenGetter() {
  return localStorage.getItem('access_token');
}

FusionChartsModule.fcRoot(FusionCharts, FusionMaps, FusionWorld, FusionTheme, DominicanRepublic);


@NgModule({
  declarations: [
    AppComponent,
    NavMenuComponent,
    DashboardComponent,
    LoginComponent,
    SidebarComponent,
    LayoutComponent,
    StatsByProvinceWidgetComponent,
    StatsByCommissionsTableWidgetComponent,
    CandidatesProgressbarComponent,
    DominicanRepublicMapComponent,
    TableStatsComponent,
    ElectoralCommissionStatsComponent,
    TransmissionTableSenatorsComponent,
    TransmissionTableDeputiesComponent,
    TransmissionTableMayorsComponent,
    TransmissionTableAldermanComponent,
    TransmissionTableDirectorsVocalsComponent,
    GlobalConsultCandidatesComponent
  ],
  imports: [
    BrowserModule.withServerTransition({appId: 'ng-cli-universal'}),
    HttpClientModule,
    FormsModule,
    BrowserAnimationsModule,
    RouterModule.forRoot([
      {path: 'login', component: LoginComponent},
      {path: 'consulta', component: GlobalConsultCandidatesComponent},
      {
        path: '',
        component: LayoutComponent,
        children: [
          {path: '', component: DashboardComponent, canActivate: [AuthGuard]},
          {
            path: 'commissions',
            loadChildren: './commissions/commissions.module#CommissionsModule',
            canActivate: [AuthGuard]
          },
          {
            path: 'candidates',
            loadChildren: './candidates/candidates.module#CandidatesModule',
            canActivate: [AuthGuard]
          }
        ]
      }
    ]),
    ReactiveFormsModule,
    NgZorroAntdModule,
    NgxMaskModule.forRoot(options),
    JwtModule.forRoot({
      config: {
        tokenGetter: jwtTokenGetter
      }
    }),
    NgCircleProgressModule.forRoot({}),
    FusionChartsModule,
    RecaptchaModule,
    RecaptchaFormsModule


  ],
  providers: [{provide: NZ_I18N, useValue: en_US}, {
    provide: HTTP_INTERCEPTORS,
    useClass: TokenInterceptor,
    multi: true
  }],
  bootstrap: [AppComponent]
})
export class AppModule {
}
