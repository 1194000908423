import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AccountsService } from '../services/accounts.service';
import { NzNotificationService } from 'ng-zorro-antd';
import { Router } from '@angular/router';
import { CurrentUserService } from '../services/current-user.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  loginForm: FormGroup;
  isLoading = false;

  constructor(private formBuilder: FormBuilder,
              private accountsService: AccountsService,
              private notification: NzNotificationService,
              private router: Router,
              private currentUserService: CurrentUserService) {
  }

  get username() {
    return this.loginForm.get('username');
  }

  get password() {
    return this.loginForm.get('password');
  }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });
    this.currentUserService.logout();
  }

  onLogin({username, password}) {
    this.isLoading = true;
    this.accountsService.authenticate({username, password}).subscribe((value: any) => {
      this.currentUserService.startSession(value);
      if (value.role === 3) {
        this.router.navigate(['candidates', 'contacts']).then(() => {
          this.isLoading = false;
        });
      } else {
        this.router.navigate(['']).then(() => {
          this.isLoading = false;
        });
      }

    }, error => {
      console.log(error);
      this.notification.error('inicio de sesión', error.error['message']);
      this.isLoading = false;
    });
  }

}
