import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Level } from './../models/level';

@Injectable({
  providedIn: 'root'
})
export class LevelService {

  constructor(@Inject('BASE_URL') private baseUrl: string, private http: HttpClient) {}

  getLevels() {
    return this.http.get<Level[]>(`${this.baseUrl}api/levels/`);
  }
}
