import { Component, EventEmitter, NgZone, OnInit, Output } from '@angular/core';
import { DashboardService } from '../../services/dashboard.service';
import { DashboardStats } from '../../models/dashboardStats';
import { PoliticalLevelHomologation } from '../../models/political-level-homologation';


@Component({
  selector: 'app-dominican-republic-map',
  templateUrl: './dominican-republic-map.component.html',
  styleUrls: ['./dominican-republic-map.component.css']
})
export class DominicanRepublicMapComponent implements OnInit {


  @Output() selectedProvince: EventEmitter<string> = new EventEmitter<string>();

  dataSource: any;
  width = 800;
  height = 400;
  type = 'dominicanrepublic';
  dataFormat = 'json';
  events: object;
  chartObj: any;
  handler: any;
  private politicalHmologation = PoliticalLevelHomologation;
  private stats: DashboardStats [];

  constructor(private zone: NgZone, private dashboardService: DashboardService) {
  }

  async ngOnInit() {

     this.stats = await this.dashboardService.getStatsGroupByProvince().toPromise();
    this.dataSource = {
      chart: {
        caption: '',
        labelsepchar: ': ',
        entityFillHoverColor: '#ffc107',
        theme: 'fusion',
        fillColor: '#573177',
        updateAnimDuration: 0.1
      },
      colorrange: {
        gradient: '0',
        color: {
          minvalue: '0',
          maxvalue: '500',
          code: '#573177'
        }
      }
    };
    this.dataSource['data'] = this.buildProvinces();
  }

  update($event) {

    console.log($event);
    // Run inside angular context
    this.zone.run(() => {

    });
  }

  entityHandler(eventObj, dataObj) {
    this.zone.run(() => {
      const {data} = this.dataSource;
      this.selectedProvince.emit(dataObj.id);
      for (let i = 0; i < data.length; i++) {
        data[i].color = this.getColor(data[i].id);
      }
    });
  }

  initialized($event) {
    this.chartObj = $event.chart;
    this.handler = this.entityHandler.bind(this);

    this.chartObj.addEventListener('entityClick', this.handler);
  }

  private buildProvinces() {
    const provinces = [];
    const pad = '00';


    for (let i = 1; i <= 38; i++) {
      const rawNumber = '' + i;
      const id = pad.substring(0, pad.length - rawNumber.length) + rawNumber;
      provinces.push({
        id: pad.substring(0, pad.length - rawNumber.length) + rawNumber,
        showLabel: 1,
        value: 0,
        fontColor: '#ffffff',
        tooltext: '<b>$lname</b>',
        color: this.getColor(id)
      });
    }

    return provinces;
  }

  private getColor( id: string) {
    const stat = this.stats.find(value => value.provinceId === this.politicalHmologation[id]);
    if (stat !== undefined) {
      const sumOfStats = stat.mayors + stat.vocal + stat.aldermen + stat.directors + stat.senators + stat.mayors;
      if (sumOfStats === 0) {
        return '#e51c4d';
      } else if (sumOfStats === 1) {
        return '#ffe300';
      } else {
        return '#ffe300';
      }
    } else {
      return '#ffe300';
    }
  }


}
