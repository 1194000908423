import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { DashboardStats } from '../models/dashboardStats';
import { StatLinesPercentage } from '../models/stat-lines-percentage';
import { ElectoralCommissionStats } from '../models/electoral-commission-stats';
import { CountryParticipation } from '../models/country-participation';
import { SenatorsTransmission } from '../dashboard/transmission-table-senators/transmission-table-senators.component';
import { DeputyTransmission } from '../dashboard/transmission-table-deputies/transmission-table-deputies.component';
import { MayorTransmission } from '../dashboard/transmission-table-mayors/transmission-table-mayors.component';
import { AldermenTransmission } from '../dashboard/transmission-table-alderman/transmission-table-alderman.component';
import { DirectorVocalTransmission } from '../dashboard/transmission-table-directors-vocals/transmission-table-directors-vocals.component';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  constructor(@Inject('BASE_URL') private baseUrl: string, private http: HttpClient) {
  }

  getStatsGroupByProvince() {
    return this.http.get<DashboardStats[]>(`${this.baseUrl}api/dashboard/stats-by-province`);
  }

  getStatsByProvinceId(provinceId: number) {
    return this.http.get<DashboardStats[]>(`${this.baseUrl}api/dashboard/stats/${provinceId}`);
  }

  getStatsLinePercentage() {
    return this.http.get<StatLinesPercentage[]>(`${this.baseUrl}api/dashboard/stats/lines-percentage`);
  }

  getElectoralCommissionStats(provinceId: any) {
    return this.http.get<ElectoralCommissionStats>(`${this.baseUrl}api/dashboard/stats/electoral-commission/${provinceId}`);
  }

  getCountryParticipation() {
    return this.http.get<CountryParticipation[]>(`${this.baseUrl}api/dashboard/stats/country-participation`);
  }

  getSenatorsTransmission() {
    return this.http.get<SenatorsTransmission[]>(`${this.baseUrl}api/dashboard/stats/senators-participation`);
  }

  getDeputiesTransmission() {
    return this.http.get<DeputyTransmission[]>(`${this.baseUrl}api/dashboard/stats/deputies-participation`);
  }

  getMayorsTransmission() {
    return this.http.get<MayorTransmission[]>(`${this.baseUrl}api/dashboard/stats/mayors-participation`);
  }

  getAldermanTransmission() {
    return this.http.get<AldermenTransmission[]>(`${this.baseUrl}api/dashboard/stats/alderman-participation`);
  }

  getDirectorsVocalsTransmission() {
    return this.http.get<DirectorVocalTransmission[]>(`${this.baseUrl}api/dashboard/stats/directors-and-vocals-participation`);
  }

  getCandidatesAbsoluteQuantity() {
    return this.http.get<number>(`${this.baseUrl}api/dashboard/stats/candidates-absolute-quantity`);
  }
}
