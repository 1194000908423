import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { CurrentUserService } from './current-user.service';
import { Observable } from 'rxjs';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(private currentUserService: CurrentUserService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!request.headers.has('Authorization') && this.currentUserService.isLogged) {
      request = request.clone({
        setHeaders: {
          Authorization: `bearer ${this.currentUserService.currentUser.token}`
        }
      });
    }
    return next.handle(request);
  }

}
