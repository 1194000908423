import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AccountsService {

  constructor(@Inject('BASE_URL') private baseUrl: string, private httpClient: HttpClient) {
  }

  authenticate(loginRequest: any) {
    return this.httpClient.post(`${this.baseUrl}api/accounts`, loginRequest);
  }
}
