import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CandidatesService } from '../services/candidates.service';
import { DocumentsService } from '../services/documents.service';
import { LevelService } from '../services/level.service';
import { DocumentTypes } from '../models/document-types';
import { Candidate } from '../models/candidate';
import { Document } from '../models/document';
import { CurrentUserService } from '../services/current-user.service';

@Component({
  selector: 'app-global-consult-candidates',
  templateUrl: './global-consult-candidates.component.html',
  styleUrls: ['./global-consult-candidates.component.css']
})
export class GlobalConsultCandidatesComponent implements OnInit {

  consultForm: FormGroup;
  photo = '/assets/img/no-photo.png';
  documentTypes: DocumentTypes[] = [];
  candidate: Candidate = <Candidate>{documents: [], level: {name: ''}};
  isLoading = false;
  isCandidateFormVisible = false;
  showNotFoundMessage = false;
  isUserLogin = false;

  @ViewChild('recaptchaComponent') recaptcha: any;

  constructor(private fb: FormBuilder, private router: Router, private candidatesService: CandidatesService,
              private documentsServices: DocumentsService, private levelService: LevelService,
              @Inject('BASE_URL') private baseUrl: string, private currentUserService: CurrentUserService) {
  }

  async ngOnInit() {
    this.isUserLogin = this.currentUserService.isLogged;
    if (this.isUserLogin) {
      this.consultForm = this.fb.group({
        identification: ['', Validators.required],
      });
    } else {
      this.consultForm = this.fb.group({
        identification: ['', Validators.required],
        recaptchaReactive: [null, Validators.required]
      });
    }


    this.documentTypes = await this.documentsServices.getDocumentTypes().toPromise();
  }

  async onSubmit() {
    this.isCandidateFormVisible = true;
    this.showNotFoundMessage = false;
    this.isLoading = true;
    const {identification} = this.consultForm.getRawValue();
    try {
      this.candidate = await this.candidatesService.getCandidateByIdentification(identification).toPromise();
      this.photo = `${this.baseUrl}api/documents/${this.candidate.id}/digital-photo`;
      this.isLoading = false;
      if (!this.isUserLogin) {
        this.recaptcha.reset();
      }

    } catch (e) {
      this.showNotFoundMessage = true;
      if (!this.isUserLogin) {
        this.recaptcha.reset();
      }
      this.isLoading = false;
    }

  }

  isSolicitudeCompleted() {
    for (const documentType of this.documentTypes) {
      if (!this.candidate.documents.some(value => value.documentTypeId === documentType.id)) {
        return false;
      }
    }

    return true;
  }

  isUploaded(documentTypeId) {
    if (this.candidate.documents !== undefined) {
      return this.candidate.documents.some((value: Document) => value.documentTypeId === documentTypeId);
    } else {
      return false;
    }
  }

  getDemarcation() {
    if (this.candidate.politicalLevels === undefined) {
      return '';
    }
    const {politicalLevels} = this.candidate;
    if (this.candidate.levelId === 1) {
      return `${politicalLevels.proviceName}`;
    } else if (this.candidate.levelId === 2) {
      return `${politicalLevels.proviceName}/${politicalLevels.circumscription}`;
    } else if (this.candidate.levelId === 3) {
      return ` ${politicalLevels.proviceName}/Mun: ${politicalLevels.municipality}`;
    } else if (this.candidate.levelId === 4) {
      return ` ${politicalLevels.proviceName}/${politicalLevels.circumscription}/Mun: ${politicalLevels.municipality}`;
    } else if (this.candidate.levelId === 7) {
      return `${politicalLevels.proviceName}`;
    } else {
      return ` ${politicalLevels.proviceName}/Mun: ${politicalLevels.municipality}/Distrito: ${politicalLevels.municipalDistricName}`;
    }
  }
}
