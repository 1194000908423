export const PoliticalLevelHomologation = {
  '01': 3,
  '02': 4,
  '03': 5,
  '04': 6,
  '34': 1,
  '06': 7,
  '28': 8,
  '11': 9,
  '08': 10,
  '29': 11,
  '09': 12,
  '10': 2,
  '12': 13,
  '30': 14,
  '14': 15,
  '31': 16,
  '15': 17,
  '32': 18,
  '16': 19,
  '35': 20,
  '18': 21,
  '19': 22,
  '20': 23,
  '33': 24,
  '36': 31,
  '23': 25,
  '24': 26,
  '25': 28,
  '26': 29,
  '21': 27,
  '37': 32,
  '27': 30
};
